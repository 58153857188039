import "../../styles/allusers.css";
import React, { useState, useEffect, useContext } from "react";
import "../../styles/Home.css";
import HowItWorks from "./HowItWorks.js";
import FeaturedProfiles from "./FeaturedProfiles.js";
import WhyAreWeBetter from "./WhyAreWeBetter.js";
import Testimonials from "./Testimonials.js";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Filteredlist from "../Dashboard/filteredlist.js";
import CookieService from "encrypted-cookie";
import Select from "react-select";

import { UserContext } from "../../context/UserContext.js"; // Adjust the path accordingly

const Filteration = ({ setLoading }) => {
  const [education, setEducation] = useState([]);
  const [professions, setProfession] = useState([]);
  const { userDetails, setUserDetails } = useContext(UserContext);
  const { errfil, setErrFil } = useContext(UserContext);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [err, setError] = useState(null);
  const [userdata, setUserdata] = useState([]);
  const [filterdata, setFilterdata] = useState({
    ageFrom: 22,
    ageTo: 27,
    withImages: true,
  });
  const [religionOptions, setReligionOptions] = useState([]);
  const [casteOptions, setCasteOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [gendergiv, setGenderGiv] = useState(["Bridegroom", "Bride"]);
  const [maritial, setMaritial] = useState([
    "Never Married",
    "Divorced",
    "Divorce with Children",

    "Widow",
    "Widower",
    "Widowed and Children",
  ]);
  const [salaryOptions] = useState([
    "Below 2LPA",
    "2 LPA - 5 LPA",
    "5 LPA - 10 LPA",
    "Above 10LPA",
  ]);
  console.log(filterdata, "filterdata");
  const navigate = useNavigate();
  const generateHeightOptions = () => {
    const heights = [];
    for (let feet = 4; feet <= 7; feet++) {
      for (let inches = 0; inches < 12; inches += 1) {
        let height = `${feet}ft ${inches}inch`;
        if (inches === 0) {
          height = `${feet}ft`;
        }
        heights.push(height);
      }
    }
    return heights;
  };
  useEffect(() => {
    const token = CookieService.checkCookie("token");

    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  useEffect(() => {
    var custom_headers = {
      "Content-Type": "application/json",
      Authorization: CookieService.getCookie("token"),
    };
    axios.get(`/api/admin/admin/get-caste`).then((response) => {
      const uniqueCastes = [
        ...new Set(response.data.data.map((user) => user.name)),
      ];
      setCasteOptions(uniqueCastes);
    });
    axios.get(`/api/admin/admin/get-city`).then((response) => {
      const uniqueCities = [
        ...new Set(response.data.data.map((user) => user.name)),
      ];
      setCityOptions(uniqueCities);
    });
    axios.get(`/api/admin/admin/get-community`).then((response) => {
      const uniqueDenomination = [
        ...new Set(response.data.data.map((user) => user.name)),
      ];
      setReligionOptions(uniqueDenomination);
    });
    axios.get(`/api/admin/admin/get-country`).then((response) => {
      const uniqueCountries = [
        ...new Set(response.data.data.map((user) => user.name)),
      ];
      setRegionOptions(uniqueCountries);
    });
    axios.get(`/api/admin/admin/get-Profession`).then((response) => {
      const uniqueProfession = [
        ...new Set(response.data.data.map((user) => user.name)),
      ];
      setProfession(uniqueProfession);
    });
    axios.get(`/api/admin/admin/get-Education`).then((response) => {
      const uniqueEducation = [
        ...new Set(response.data.data.map((user) => user.name)),
      ];
      console.log(uniqueEducation, "uniqueEducation");
      setEducation(uniqueEducation);
    });
  }, []);

  const handleFilter = async () => {
    const personalDataString = localStorage.getItem("personal");
    const personalData = JSON.parse(personalDataString);

    if (personalData) {
      let genderParam = "";
      let heightCM = "";
      console.log(personalData.height, "hhh");
      if (personalData.height && personalData.height !== "NA") {
        heightCM = personalData.height;
      }
      if (personalData.lookingFor) {
        genderParam = personalData.lookingFor;
      } else if (personalData["Bride/Bridegroom"]) {
        if (personalData["Bride/Bridegroom"] === "Bride") {
          genderParam = "Bridegroom";
        } else {
          genderParam = "Bride";
        }
      }

      if (!isAuthenticated) {
        setTimeout(() => {
          navigate("/signin");
        }, 1500);
        return;
      }
      var custom_headers = {
        "Content-Type": "application/json",
        Authorization: CookieService.getCookie("token"),
        gender: genderParam,
        height: heightCM,
      };
      try {
        setUserDetails([]);
        setLoading(true);
        setErrFil("");
        const response = await axios.get(`/api/users/usersdetails`, {
          params: filterdata,
          headers: custom_headers,
        });

        const data = response.data;
        if (data.message) {
          setLoading(false);
          setUserdata([]);
          setErrFil(data.message);
          setUserDetails([]);
        } else {
          setLoading(false);
          setErrFil("");
          setUserdata(data);

          setUserDetails((prevData) => [...prevData, ...data]);
        }
      } catch (error) {
        console.error("Error fetching filtered data:", error);
      }
    }
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;

  //   setFilterdata({
  //     ...filterdata,
  //     [name]: value,
  //   });
  // };
  const handleInputChange = (selectedOption, name) => {
    setFilterdata({
      ...filterdata,
      [name]: selectedOption ? selectedOption.value : "", // Handle the possibility of no selection
    });
  };
  const handleinput = (e) => {
    console.log(e.target.name, e.target.value, "eeeee");
    setFilterdata({
      ...filterdata,
      [e.target.name]: e.target.value, // Handle the possibility of no selection
    });
  };

  const handleCheckboxChange = (e) => {
    setFilterdata({
      ...filterdata,
      withImages: e.target.checked, // Update `withImages` based on checkbox
    });
  };

  return (
    <div className="header">
      <h1 className="title">The World's No.1 Matrimonial Service</h1>
      <div className="search-box">
        {/* bride */}
        <div className="search-field">
          <label className="im">I'm looking for a</label>

          <Select
            options={gendergiv.map((val) => ({
              value: val,
              label: val,
            }))}
            onChange={(selectedOption) =>
              handleInputChange(selectedOption, "Bride/Bridegroom")
            }
            placeholder="Select"
          />
        </div>

        {/* age */}
        <div className="search-field">
          <label>Age</label>
          
          <input
            type="number"
            defaultValue="22"
            min="18"
            value={filterdata.ageFrom}
            name="ageFrom"
            onChange={handleinput}
          />
          <span>to</span>
          <input
            type="number"
            defaultValue="27"
            min="18"
            value={filterdata.ageTo}
            name="ageTo"
            onChange={handleinput}
          />
        </div>

        {/* height */}
        <div className="search-field">
          <label>Height </label>
          {/* <select name="heightfrom" onChange={handleInputChange}>
            <option>Select</option>
            {generateHeightOptions().map((religion, index) => (
              <option key={index} value={religion}>
                {religion}
              </option>
            ))}
          </select> */}
          <Select
            options={generateHeightOptions().map((val) => ({
              value: val,
              label: val,
            }))}
            onChange={(selectedOption) =>
              handleInputChange(selectedOption, "heightfrom")
            }
            placeholder="Select"
          />
          <span>to</span>
          {/* <select name="heightto" onChange={handleInputChange}>
            <option>Select</option>
            {generateHeightOptions().map((religion, index) => (
              <option key={index} value={religion}>
                {religion}
              </option>
            ))}
          </select> */}

          <Select
            options={generateHeightOptions().map((val) => ({
              value: val,
              label: val,
            }))}
            onChange={(selectedOption) =>
              handleInputChange(selectedOption, "heightto")
            }
            placeholder="Select"
          />
        </div>
        {/* denomination */}
        <div className="search-field">
          <label>Denomination</label>
          {/* <select name="Denomination" onChange={handleInputChange}>
            <option>Select</option>
            {religionOptions.map((religion, index) => (
              <option key={index} value={religion}>
                {religion}
              </option>
            ))}
          </select> */}

          <Select
            options={religionOptions.map((val) => ({
              value: val,
              label: val,
            }))}
            onChange={(selectedOption) =>
              handleInputChange(selectedOption, "Denomination")
            }
            placeholder="Select"
          />
        </div>
        {/* cast */}
        {/* <div className="search-field">
          <label>Cast</label>
          <select name="Cast" onChange={handleInputChange}>
            <option>Select</option>
            {casteOptions.map((caste, index) => (
              <option key={index} value={caste}>
                {caste}
              </option>
            ))}
          </select>
        </div> */}

        <div className="search-field">
          <label>Cast</label>
          <Select
            options={casteOptions.map((caste) => ({
              value: caste,
              label: caste,
            }))}
            onChange={(selectedOption) =>
              handleInputChange(selectedOption, "Cast")
            }
            placeholder="Select"
          />
        </div>
        {/* education */}
        {/* <div className="search-field">
          <label>Education</label>
          <select name="Education" onChange={handleInputChange}>
            <option>Select</option>
            {education.map((caste, index) => (
              <option key={index} value={caste}>
                {caste}
              </option>
            ))}
          </select>
        </div> */}

        <div className="search-field">
          <label>Education</label>
          <Select
            options={education.map((educat) => ({
              value: educat,
              label: educat,
            }))}
            onChange={(selectedOption) =>
              handleInputChange(selectedOption, "Education")
            }
            placeholder="Select"
          />
        </div>
        {/* profession */}
        <div className="search-field">
          <label>Profession</label>
          {/* <select name="Profession" onChange={handleInputChange}>
            <option>Select</option>
            {professions.map((caste, index) => (
              <option key={index} value={caste}>
                {caste}
              </option>
            ))}
          </select> */}
          <Select
            options={professions.map((profess) => ({
              value: profess,
              label: profess,
            }))}
            onChange={(selectedOption) =>
              handleInputChange(selectedOption, "Profession")
            }
            placeholder="Select"
          />
        </div>

        {/* maital status */}
        <div className="search-field">
          <label>Marital Status</label>
          {/* <select name="MaritalStatus" onChange={handleInputChange}>
            <option>Select</option>
            {maritial.map((status, index) => (
              <option key={index} value={status}>
                {status}
              </option>
            ))}
          </select> */}

          <Select
            options={maritial.map((val) => ({
              value: val,
              label: val,
            }))}
            onChange={(selectedOption) =>
              handleInputChange(selectedOption, "MaritalStatus")
            }
            placeholder="Select"
          />
        </div>
        {/* city */}
        <div className="search-field">
          <label>City</label>
          {/* <select name="City" onChange={handleInputChange}>
            <option>Select</option>
            {cityOptions.map((City, index) => (
              <option key={index} value={City}>
                {City}
              </option>
            ))}
          </select> */}
          <Select
            options={cityOptions.map((val) => ({
              value: val,
              label: val,
            }))}
            onChange={(selectedOption) =>
              handleInputChange(selectedOption, "City")
            }
            placeholder="Select"
          />
        </div>
        {/* country */}
        <div className="search-field">
          <label>Country</label>
          {/* <select name="Country" onChange={handleInputChange}>
            <option>Select</option>
            {regionOptions.map((region, index) => (
              <option key={index} value={region}>
                {region}
              </option>
            ))}
          </select> */}

          <Select
            options={regionOptions.map((val) => ({
              value: val,
              label: val,
            }))}
            onChange={(selectedOption) =>
              handleInputChange(selectedOption, "Country")
            }
            placeholder="Select"
          />
        </div>
        {/* salary */}
        <div className="search-field">
          <label>Salary</label>
          {/* <select name="salary" onChange={handleInputChange}>
            <option>Select</option>
            {salaryOptions.map((salary, index) => (
              <option key={index} value={salary}>
                {salary}
              </option>
            ))}
          </select> */}

          <Select
            options={salaryOptions.map((val) => ({
              value: val,
              label: val,
            }))}
            onChange={(selectedOption) =>
              handleInputChange(selectedOption, "salary")
            }
            placeholder="Select"
          />
        </div>

        <div className="search-field withimages">
          <label htmlFor="withimg">With Images</label>
          <input
            id="withimg"
            type="checkbox"
            name="withImages"
            checked={filterdata.withImages}
            onChange={handleCheckboxChange}
          />
        </div>

        <button className="search-button" onClick={handleFilter}>
          Search
        </button>
      </div>
    </div>
  );
};

export default Filteration;
