// import React, { useState } from "react";
// import "../../styles/FilteredList.css";
// import Modal from "./model";

// const Filteredlist = (props) => {
//   const { data } = props;

//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 6;

//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentData = data.slice(indexOfFirstItem, indexOfLastItem);
//   console.log(currentData, "currentdata");
//   const totalPages = Math.ceil(data.length / itemsPerPage);
//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   return (
//     <div className="user-list">
//       <div className="user-details">
//         {currentData.map((each) => (
//           <div key={each._id} className="each-profile">
//             <Modal data={each} />
//             <div className="profile-info">
//               <h2>{each.Name}</h2>
//               <h4>
//                 <span>JIO_ID: </span>
//                 {each.JIOID}
//               </h4>
//               <p>Age: {each.Age}</p>
//               <p>Denomination: {each.Denomination}</p>
//               <p>Bride or Groom: {each["Bride/Bridegroom"]}</p>
//               <p>Cast: {each.Cast}</p>
//               <p>Marital Status: {each["MaritalStatus"]}</p>
//               <p>City: {each.City}</p>
//               <p>Salary: {each["Salary-PA_Standardized"] || "Not-Specified"}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//       <div className="pagination">
//         {Array.from({ length: totalPages }, (_, index) => (
//           <button
//             key={index + 1}
//             onClick={() => paginate(index + 1)}
//             className={currentPage === index + 1 ? "active" : ""}
//           >
//             {index + 1}
//           </button>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Filteredlist;
import React, { useState } from "react";
import "../../styles/FilteredList.css";
import Modal from "./model";
import { GiQueenCrown } from "react-icons/gi";

const Filteredlist = (props) => {
  const { data } = props;

  // Ensure data is present and it's an array
  const validData = Array.isArray(data) && data.length > 0 ? data : [];

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = validData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(validData.length / itemsPerPage);

  // Handle next page
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Handle previous page
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleProfileClick = (jioId) => {
    window.open(`/user/${jioId}`, "_blank");
  };
  console.log(currentData, "currentData");

  return (
    <div className="user-list">
      <div className="user-details">
        {currentData.map((each) => (
          <div key={each._id} className="each-profile">
            <Modal data={each} />
            <div
              className="profile-info"
              onClick={() => handleProfileClick(each.JIOID)}
            >
              <h2>
                {each.Name}{" "}
                {each.membership_status &&
                each.membership_status !== "Unpaid" &&
                each.membership_status !== "Expired" ? (
                  <span>
                    {" "}
                    <GiQueenCrown
                      className={`membership-status ${each.membership_status}`}
                    />
                  </span>
                ) : (
                  ""
                )}
              </h2>
              <h4>
                <span>J{each.JIOID}</span>
              </h4>
              <p>Age: {each.Age}</p>
              <p>Height: {each.height || "Not Specified"}</p>
              <p>Bride or Groom: {each["Bride/Bridegroom"]}</p>
              <p>Cast: {each.Cast}</p>
              <p>Denomination: {each.Denomination}</p>
              <p>Marital Status: {each["MaritalStatus"]}</p>
              <p>City: {each.City}</p>
              <p>Salary: {each["Salary-PA_Standardized"] || "Not-Specified"}</p>
              <p>Education: {each["Education_not_Standadize"]}</p>
              <p>Profession: {each.Profession}</p>
            </div>
          </div>
        ))}
      </div>

      {validData.length > 0 && (
        <div className="pagination">
          <button onClick={handlePrevPage} disabled={currentPage === 1}>
            Previous
          </button>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default Filteredlist;
