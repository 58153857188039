import React, { useState } from "react";
import "../../styles/profileid.css";
import CookieService from "encrypted-cookie";
import axios from "axios";
import { GiQueenCrown } from "react-icons/gi";

const ProfileId = () => {
  const [searchValue, setSearchValue] = useState("");
  const [searchType, setSearchType] = useState("JIOID");
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [errorContact, setErrorContact] = useState({}); // Error for each user
  const [loading, setLoading] = useState(false);
  const [showVal, setShowVal] = useState({});
  const [count, setCount] = useState("");
  const [remainingDays, setRemainingDays] = useState({}); // To track remaining days for each user
  const [mydetails, setMydetails] = useState("");
  console.log(remainingDays, "remainingDays", errorContact, "errorContact");
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!searchValue) {
      setError("Please enter a valid search value.");
      return;
    }

    setLoading(true);
    setError(null);

    const custom_headers = {
      "Content-Type": "application/json",
      Authorization: CookieService.getCookie("token"),
    };

    const searchParam =
      searchType === "JIOID" ? { id: searchValue } : { Name: searchValue };

    axios
      .get(`/api/users/GetUsersById`, {
        params: searchParam,
        headers: custom_headers,
      })
      .then((response) => {
        setData(response.data.users);
        console.log("Fetched users:", response.data.users);
      })
      .catch((error) => {
        setError("Error fetching users data. Please try again.");
        console.error("Error fetching users data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchTypeChange = (e) => {
    setSearchType(e.target.value);
  };

  const handleReveal = (userId) => {
    const personalDataString = localStorage.getItem("personal");
    const personalData = JSON.parse(personalDataString);
    setCount("");
    setMydetails(personalData);
    setErrorContact({});

    // Reset the remaining days for the user being revealed
    setRemainingDays((prev) => ({ ...prev, [userId]: null }));

    axios
      .get(`/api/users/CheckContactLimit`, {
        params: { id: personalData.JIOID },
      })
      .then((response) => {
        if (response.data.message === "Contact Revealed") {
          // Update remaining days for the specific user
          console.log(response);
          if (response.data.remainingDays) {
            const { months, days } = response.data.remainingDays;
            if (months == 0 && days == 0) {
              setErrorContact((prev) => ({
                ...prev,
                [userId]: "Your Package is Expired",
              }));
              return;
            }
            setRemainingDays((prev) => ({
              ...prev,
              [userId]: `${months} month${
                months !== 1 ? "s" : ""
              } and ${days} day${days !== 1 ? "s" : ""}`,
            }));
          }
          setCount(response.data.limitcount);
          setShowVal((prev) => ({ ...prev, [userId]: true }));
          // Clear error for the user when successfully revealed
          setErrorContact((prev) => ({ ...prev, [userId]: null }));
        }
      })
      .catch((error) => {
        // Set error only for the specific user
        setErrorContact((prev) => ({
          ...prev,
          [userId]: error.response?.data?.error || "An error occurred",
        }));
        console.error("Error fetching contact limit:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="bottom-section">
      <form onSubmit={handleSubmit}>
        <div className="radios">
          <label>
            <input
              type="radio"
              name="searchType"
              value="JIOID"
              checked={searchType === "JIOID"}
              onChange={handleSearchTypeChange}
            />
            Search by JIOID
          </label>
          <label>
            <input
              type="radio"
              name="searchType"
              value="Name"
              checked={searchType === "Name"}
              onChange={handleSearchTypeChange}
            />
            Search by Name
          </label>
        </div>
        <div className="searchSubmit">
          <input
            type="text"
            placeholder={`Enter ${
              searchType === "JIOID" ? "JIOID" : "Name"
            } here...`}
            value={searchValue}
            onChange={handleChange}
          />
          <button className="line-shade" type="submit" disabled={loading}>
            {loading ? "Loading..." : "Submit"}
          </button>
        </div>
      </form>

      {error && <p className="error-message">{error}</p>}

      <div className="profiles-container">
        {data.length > 0 &&
          data.map((each, index) => (
            <div key={index} className="profile-user">
              {each.images && each.images.length > 0 ? (
                <img
                  className="profile-img"
                  src={`data:image/jpeg;base64,${each.images[0]}`}
                  alt={`Profile image for ${each.Name}`}
                />
              ) : (
                <img src={require("../../images/OIP.jpeg")} alt="default" />
              )}
              <div className="profile-info">
                <h2>
                  {each.Name}
                  {each.membership_status &&
                  each.membership_status !== "Unpaid" &&
                  each.membership_status !== "Expired" ? (
                    <span>
                      {" "}
                      <GiQueenCrown
                        className={`membership-status ${each.membership_status}`}
                      />
                    </span>
                  ) : (
                    ""
                  )}
                </h2>
                <p>
                  {" "}
                  <span style={{ fontWeight: "bold" }}> J{each.JIOID}</span>
                </p>
                <p>
                  {" "}
                  <span style={{ fontWeight: "bold" }}> Age:</span> {each.Age}
                </p>
                <p>
                  {" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    Denomination:
                  </span>{" "}
                  {each.Denomination}
                </p>
                <p>
                  {" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    Bride or Groom:{" "}
                  </span>{" "}
                  {each["Bride/Bridegroom"]}
                </p>
                <p>
                  {" "}
                  <span style={{ fontWeight: "bold" }}> Cast:</span> {each.Cast}
                </p>
                <p>
                  {" "}
                  <span style={{ fontWeight: "bold" }}> Marital Status: </span>
                  {each.MaritalStatus}
                </p>
                <p>
                  {" "}
                  <span style={{ fontWeight: "bold" }}> City:</span> {each.City}
                </p>
                <p>
                  {" "}
                  <span style={{ fontWeight: "bold" }}> Salary:</span>{" "}
                  {each["Salary-PA"] || "Not-Specified"}
                </p>

                <div className="contactinfo">
                  {!showVal[each.JIOID] ? (
                    <>
                      <span
                        onClick={() => handleReveal(each.JIOID)}
                        className="line-shade"
                        style={{ width: "fit-content" }}
                      >
                        Show Contact Details
                      </span>
                      {/* Show error only for the user that is clicked */}
                      {errorContact[each.JIOID] && (
                        <p className="error-message">
                          {errorContact[each.JIOID]}
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      {remainingDays[each.JIOID] !== null && (
                        <p style={{ color: "red" }}>
                          You have <strong>{remainingDays[each.JIOID]}</strong>{" "}
                          remaining and can reveal <strong>{count}</strong> more
                          contacts.
                        </p>
                      )}
                      <p>Mobile Number: {each.Mobile}</p>
                      <p>Email: {each.email}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ProfileId;
