import "./App.css";
import React, { useState, useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Navbar from "./components/common/Navbar.js";
import HomePage from "./components/Home/Home.js";
import Footer from "./components/common/Footer.js";
import LoginPage from "./components/signin-out/LoginPage.js";
import SignUpPage from "./components/signin-out/SignUpPage.js";
import FreeQuoteForm from "./components/Home/FreeQuoteForm.js";
import Modal from "./components/Home/Modal.js";
import whatsapp from "./images/whatsapp-v1.png";
import ForgotPasswordPage from "./components/signin-out/forgotpassword.js";
import ResetPassword from "./components/signin-out/resetpassword.js";
import Filteredlist from "./components/Dashboard/filteredlist.js";

import CanvasUpscaledImage from "./components/image.js";
import Subscribe from "./components/subscribe.js";

import CreateProfile from "./components/signin-out/profilecreating.js";

import PaymentPage from "./components/payment/PymentGate.js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { UserContext, UserProvider } from "./context/UserContext";
import Landing from "./components/landingpage.js";
import LandingNavbar from "./components/common/LandingNavbar.js";
import CookieService from "encrypted-cookie";
import ScrollToTop from "./components/scrollTop.js";
import ProfileId from "./components/Dashboard/profileId.js";
import PrivacyPolicy from "./components/common/Privacy_Policy.js";
import Terms_Condition from "./components/common/Terms_Condition.js";
import Refund_Policies from "./components/common/Refund_Policies.js";
import ScrollToTopButton from "./components/scrollBar.js";
import { ToastContainer } from "react-toastify";
import AllUsers from "./components/Dashboard/allusers.js";

import "react-toastify/dist/ReactToastify.css";
import NotFound from "./components/notfound.js";

import AddUploadImg from "./components/Dashboard/UploadImg.js";
import Help from "./components/help/help.js";
import UserDetail from "./components/Home/UserDetails.js";

function App() {
  const [showFixedButtons, setShowFixedButtons] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = CookieService.checkCookie("token");

    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setShowFixedButtons(true);
      } else {
        setShowFixedButtons(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleQuoteClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  console.log(isAuthenticated, "isauthenticated");

  return (
    <UserProvider>
      <div className="App">
        {location.pathname === "/" ? <LandingNavbar /> : <Navbar />}
        <GoogleOAuthProvider clientId="389834265463-bt4aon9p21jdqjbiqrlvhfcuov5g0m8l.apps.googleusercontent.com">
          <ScrollToTop />
          <ToastContainer />
          <ScrollToTopButton />
          {/* <Routes>
            <Route exact path="/" element={<Landing />} />
            <Route path="/dashboard" element={<HomePage />} />
            <Route path="/signin" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage data={true} />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/forgot-password/:token" element={<ResetPassword />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<Terms_Condition />} />
            <Route path="/refund-policy" element={<Refund_Policies />} />{" "}
            <Route path="/filtered-list" element={<Filteredlist />} />
            <Route path="/subscribe-plan" element={<Subscribe />} />
            <Route path="/profileid" element={<ProfileId />} />
            <Route
              path="/subscribe-plan/PaymentGate"
              element={<PaymentPage />}
            />
            <Route path="/Register" element={<CreateProfile />} />
            <Route path="/my-profile" element={<CreateProfile view={true} />} />
          </Routes> */}

          <Routes>
            <Route exact path="/" element={<Landing />} />
            <Route path="/signin" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage data={true} />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/forgot-password/:token" element={<ResetPassword />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<Terms_Condition />} />
            <Route path="/refund-policy" element={<Refund_Policies />} />

            <Route path="/dashboard" element={<HomePage />} />
            <Route path="/filtered-list" element={<Filteredlist />} />
            <Route path="/subscribe-plan" element={<Subscribe />} />
            <Route path="/profileid" element={<ProfileId />} />
            <Route
              path="/subscribe-plan/PaymentGate"
              element={<PaymentPage />}
            />
            {/* {isAuthenticated ? (
              <Route path="/Register" element={<CreateProfile />} />
            ) : (
              <Route path="/signin" element={<LoginPage />} />
            )} */}
            <Route
              path="/Register"
              element={
                isAuthenticated ? <CreateProfile /> : <Navigate to="/signin" />
              }
            />

            <Route path="/my-profile" element={<CreateProfile view={true} />} />
            <Route path="/Add_Edit_Photos" element={<AddUploadImg />} />
            <Route path="/help" element={<Help />} />
            <Route path="/get-all-users" element={<AllUsers />} />

            <Route path="/user/:jioId" element={<UserDetail />} />

            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </GoogleOAuthProvider>
        <Footer />
        {showFixedButtons && (
          <div className="fixed-buttons">
            <a
              href="https://wa.me/+918885073555?text=Hello, I would like to get more information about your services."
              className="whatsapp-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={whatsapp} alt="WhatsApp" className="whats" /> Chat with
              us
            </a>
            <a className="quote-button" onClick={handleQuoteClick}>
              Get Free Quote
            </a>
          </div>
        )}

        <Modal show={showModal} onClose={handleCloseModal}>
          <FreeQuoteForm />
        </Modal>
        {/* <CanvasUpscaledImage
        className="enhanced-image"
        src={require("../src/images/S3kLD.jpg")}
      />
      <img src={require("../src/images/S3kLD.jpg")} />
     */}
      </div>
    </UserProvider>
  );
}

export default App;
