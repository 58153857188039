import React, { useEffect, useState } from "react";
import axios from "axios";
import CookieService from "encrypted-cookie";
import { useParams } from "react-router-dom";
import "../../styles/UserDetails.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import defaultAvatar from "../../images/OIP.jpeg";

const UserDetail = () => {
  const { jioId } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showVal, setShowVal] = useState(false);
  const [errorContact, setErrorContact] = useState(null);
  const [remainingDays, setRemainingDays] = useState(null);
  const [contactLimit, setContactLimit] = useState(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const handleReveal = () => {
    const personalDataString = localStorage.getItem("personal");
    const personalData = JSON.parse(personalDataString);

    axios
      .get(`/api/users/CheckContactLimit`, {
        params: { id: personalData.JIOID },
      })
      .then((response) => {
        console.log("API response:", response.data);
        if (response.data.message === "Contact Revealed") {
          if (response.data.remainingDays) {
            const { months, days } = response.data.remainingDays;
            if (months == 0 && days == 0) {
              setErrorContact("Your Package is Expired");
              return;
            }
            setRemainingDays(
              `${months} month${months !== 1 ? "s" : ""} and ${days} day${days !== 1 ? "s" : ""
              }`
            );
          }
          setContactLimit(response.data.limitcount);
          setShowVal(true);
          setErrorContact();
        }
      })
      .catch((error) => {
        setErrorContact(error.response.data.error);
        console.error("Error fetching users data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = CookieService.getCookie("token");
      const custom_headers = { Authorization: token };
      const personalDataString = localStorage.getItem("personal");
      const personalData = JSON.parse(personalDataString);
      const viewerJioId = personalData.JIOID;
    
      try {
        const response = await axios.get(`/api/users/GetUsersById?id=${jioId}`, {
          headers: custom_headers,
        });
    
        setUserDetails(response.data.users[0]);
        setImages(response.data.users[0].images);
    
        await axios.post("/api/users/logView", {
          viewerJioId: viewerJioId,
          viewedProfileJioId: jioId,
        }, {
          headers: custom_headers,
        });
    
        console.log("Profile view logged successfully.");
      } catch (error) {
        console.error("Error fetching user details or logging view:", error.response ? error.response.data : error.message);
      } finally {
        setLoading(false);
      }
    };
    if (jioId) {
      fetchUserDetails();
    }
  }, [jioId]);


  if (loading) return <div>Loading...</div>;

  return (
    <div className="user-detail">
      <div className="userImage">
        {images && images.length > 0 ? (
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index} className="images">
                {image ? (
                  <img
                    src={`data:image/jpeg;base64,${image}`}
                    alt={`User Image ${index + 1}`}
                  />
                ) : (
                  <img
                    src={defaultAvatar}
                    alt="Default Avatar"
                    className="default-avatar"
                  />
                )}
              </div>
            ))}
          </Slider>
        ) : (
          <img
            src={defaultAvatar}
            alt="Default Avatar"
            className="default-avatar"
          />
        )}
      </div>

      {/* User details section */}
      <div className="userDetails">
        <div className="name">
          <h2>{userDetails.Name}</h2>
        </div>
        <div className="aboutme">
          <p>
            <strong>J{userDetails.JIOID}</strong>
          </p>
          <p>
            <strong>About Me:</strong> {userDetails.AboutMe || "Not Specified"}
          </p>
        </div>
        <div className="basic-information">
          <p>
            <strong>Age:</strong> {userDetails.Age || "Not Specified"}
          </p>
          <p>
            <strong>Height:</strong> {userDetails.height || "Not Specified"}
          </p>
          <p>
            <strong>Gender:</strong> {userDetails.gender || "Not Specified"}
          </p>
          <p>
            <strong>Education:</strong>{" "}
            {userDetails.Education_not_Standadize || "Not Specified"}
          </p>
          <p>
            <strong>Profession:</strong>
            {userDetails.Profession || "Not Specified"}
          </p>
          <p>
            <strong>Salary (PA):</strong>{" "}
            {userDetails["Salary-PA_Standardized"] || "Not Specified"}
          </p>
          <p>
            <strong>Date of Birth:</strong>{" "}
            {new Date(userDetails.DateOfBirth).toLocaleDateString()}
          </p>
          <p>
            <strong>Marital Status:</strong>{" "}
            {userDetails.MaritalStatus || "Not Specified"}
          </p>
        </div>
        <div className="family">
          <p>
            <strong>About Family:</strong>{" "}
            {userDetails.AboutFamily || "Not Specified"}
          </p>
          <p>
            <strong>Preferred Match:</strong>{" "}
            {userDetails.Prefering || "Not Specified"}
          </p>
        </div>
        <div className="adress">
          <p>
            <strong>Address:</strong> {userDetails.Address || "Not Specified"}
          </p>
          <p>
            <strong>City:</strong> {userDetails.City || "Not Specified"}
          </p>
          <p>
            <strong>Country:</strong> {userDetails.Country || "Not Specified"}
          </p>
          <p>
            <strong>Cast:</strong> {userDetails.Cast || "Not Specified"}
          </p>
        </div>
        <div className="conatctinfo">
          {!showVal ? (
            <span
              onClick={handleReveal}
              className="line-shade"
              style={{ width: "fit-content" }}
            >
              Show Contact Details
            </span>
          ) : (
            <>
              <p>Mobile Number: {userDetails.Mobile || "Not Specified"}</p>
              <p>Email: {userDetails.email || "Not Specified"}</p>
              <p style={{ color: "red" }}>
                You have
                <strong>{remainingDays}</strong> remaining and can reveal
                <strong>{contactLimit}</strong> more contacts.
              </p>
            </>
          )}
          {errorContact && <p className="error-message">{errorContact}</p>}
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
